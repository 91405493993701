/* ##remfixer: 1080 */

.WealDetails {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.WealDetails .AppBlockNormal .text {
  width: 5.555556rem;
  margin-top: 0.37037rem;
}

.WealDetails .AppBlockNormal .app-title {
  margin-bottom: 0.166667rem;
}

.WealDetails .block_head-box {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.WealDetails .coupon {
  margin-top: 0.222222rem;
  margin-bottom: 0;
}

.downloadApp {
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 1.203704rem;
  line-height: 1.203704rem;
  border-radius: 1.203704rem;
  border: 1px solid rgba(252, 91, 35, 0.35);
  margin: 0.240741rem 0 1.111111rem;
  text-align: center;
  background-color: rgba(252, 91, 35, 0.05);
  overflow: hidden;
  color: #000;
}